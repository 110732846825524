h1 {
  color: white;
  text-align: center;
  font-family: 'Borel';
}

p {
  color: white;
  font-family: 'Nunito';
  font-size: 1.3rem;
  text-align: center;
}

.App {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}